<div
  class="tw-flex tw-items-center tw-gap-2 tw-p-2 tw-pl-4 tw-text-contrast tw-border-transparent tw-bg-clip-padding tw-border-solid tw-border-b tw-border-0"
  [ngClass]="bannerClass"
  [attr.role]="useAlertRole ? 'status' : null"
  [attr.aria-live]="useAlertRole ? 'polite' : null"
>
  <i class="bwi tw-align-middle tw-text-base" [ngClass]="icon" *ngIf="icon" aria-hidden="true"></i>
  <span class="tw-grow tw-text-base">
    <ng-content></ng-content>
  </span>
  <button
    *ngIf="showClose"
    type="button"
    bitIconButton="bwi-close"
    buttonType="contrast"
    size="default"
    (click)="onClose.emit()"
    [attr.title]="'close' | i18n"
    [attr.aria-label]="'close' | i18n"
  ></button>
</div>
