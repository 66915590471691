<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="shareTitle">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise">
      <div class="modal-header">
        <h1 class="modal-title" id="shareTitle">
          {{ "moveToOrganization" | i18n }}
          <small *ngIf="cipher">{{ cipher.name }}</small>
        </h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ng-container *ngIf="organizations$ | async as organizations">
        <div class="modal-body" *ngIf="!organizations || !organizations.length">
          {{ "noOrganizationsList" | i18n }}
        </div>
        <div class="modal-body" *ngIf="organizations && organizations.length">
          <p>{{ "moveToOrgDesc" | i18n }}</p>
          <div class="form-group">
            <label for="organization">{{ "organization" | i18n }}</label>
            <select
              id="organization"
              name="OrganizationId"
              [(ngModel)]="organizationId"
              class="form-control"
              (change)="filterCollections()"
            >
              <option *ngFor="let o of organizations" [ngValue]="o.id">{{ o.name }}</option>
            </select>
          </div>
          <div class="d-flex">
            <h3>{{ "collections" | i18n }}</h3>
            <div class="ml-auto d-flex" *ngIf="collections && collections.length">
              <button type="button" (click)="selectAll(true)" class="btn btn-link btn-sm py-0">
                {{ "selectAll" | i18n }}
              </button>
              <button type="button" (click)="selectAll(false)" class="btn btn-link btn-sm py-0">
                {{ "unselectAll" | i18n }}
              </button>
            </div>
          </div>
          <div *ngIf="!collections || !collections.length">
            {{ "noCollectionsInList" | i18n }}
          </div>
          <table
            class="table table-hover table-list mb-0"
            *ngIf="collections && collections.length"
          >
            <tbody>
              <tr *ngFor="let c of collections; let i = index" (click)="check(c)">
                <td class="table-list-checkbox">
                  <input
                    type="checkbox"
                    [(ngModel)]="c.checked"
                    name="Collection[{{ i }}].Checked"
                    appStopProp
                  />
                </td>
                <td>
                  {{ c.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary btn-submit manual"
            [disabled]="form.loading || !canSave"
            [ngClass]="{ loading: form.loading }"
            *ngIf="organizations && organizations.length"
          >
            <i
              class="bwi bwi-spinner bwi-spin"
              title="{{ 'loading' | i18n }}"
              aria-hidden="true"
            ></i>
            <span>{{ "save" | i18n }}</span>
          </button>
          <a
            href="#"
            routerLink="/create-organization"
            class="btn btn-primary"
            *ngIf="!organizations || !organizations.length"
          >
            {{ "newOrganization" | i18n }}
          </a>
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
            {{ "cancel" | i18n }}
          </button>
        </div>
      </ng-container>
    </form>
  </div>
</div>
