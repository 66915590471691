<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog dialogSize="default" [title]="(add ? 'addStorage' : 'removeStorage') | i18n">
    <ng-container bitDialogContent>
      <p bitTypography="body1">{{ (add ? "storageAddNote" : "storageRemoveNote") | i18n }}</p>
      <div class="tw-grid tw-grid-cols-12">
        <bit-form-field class="tw-col-span-7">
          <bit-label>{{ (add ? "gbStorageAdd" : "gbStorageRemove") | i18n }}</bit-label>
          <input bitInput type="number" formControlName="storageAdjustment" />
          <bit-hint *ngIf="add">
            <strong>{{ "total" | i18n }}:</strong>
            {{ formGroup.get("storageAdjustment").value || 0 }} GB &times;
            {{ storageGbPrice | currency: "$" }} = {{ adjustedStorageTotal | currency: "$" }} /{{
              interval | i18n
            }}
          </bit-hint>
        </bit-form-field>
      </div>
    </ng-container>
    <ng-container bitDialogFooter>
      <button type="submit" bitButton bitFormButton buttonType="primary">
        {{ "submit" | i18n }}
      </button>
      <button
        type="button"
        bitButton
        bitFormButton
        buttonType="secondary"
        [bitDialogClose]="DialogResult.Cancelled"
      >
        {{ "cancel" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
<app-payment [showMethods]="false"></app-payment>
