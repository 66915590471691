<h1 class="tw-text-3xl !tw-text-alt2">Start your 7-day free trial for Teams</h1>
<div class="tw-pt-20">
  <h2 class="tw-text-2xl">
    Strengthen business security with an easy-to-use password manager your team will love.
  </h2>
</div>
<ul class="tw-mt-12 tw-flex tw-flex-col tw-gap-10 tw-text-2xl tw-text-main tw-list-none tw-pl-0">
  <li class="tw-flex tw-items-center">
    <i class="bwi bwi-lg bwi-check-circle tw-mr-4 tw-flex-none"></i
    ><span class="tw-flex-auto"
      >Instantly and securely share credentials with the groups and individuals who need them</span
    >
  </li>
  <li class="tw-flex tw-items-center">
    <i class="bwi bwi-lg bwi-check-circle tw-mr-4 tw-flex-none"></i
    ><span class="tw-flex-auto"
      >Migrate to Bitwarden in minutes with comprehensive import options</span
    >
  </li>
  <li class="tw-flex tw-items-center">
    <i class="bwi bwi-lg bwi-check-circle tw-mr-4 tw-flex-none"></i
    ><span class="tw-flex-auto"
      >Save time and increase productivity with autofill and instant device syncing</span
    >
  </li>
  <li class="tw-flex tw-items-center">
    <i class="bwi bwi-lg bwi-check-circle tw-mr-4 tw-flex-none"></i
    ><span class="tw-flex-auto"
      >Enhance security practices across your team with easy user management</span
    >
  </li>
</ul>
<div class="tw-mt-28 tw-flex tw-flex-col tw-items-center tw-gap-5">
  <app-logo-badges></app-logo-badges>
</div>
