import { ApiService } from "../../../../abstractions/api.service";

import { Forwarder } from "./forwarder";
import { ForwarderOptions } from "./forwarder-options";

export class AnonAddyForwarder implements Forwarder {
  async generate(apiService: ApiService, options: ForwarderOptions): Promise<string> {
    if (options.apiKey == null || options.apiKey === "") {
      throw "Invalid addy.io API token.";
    }
    if (options.anonaddy?.domain == null || options.anonaddy.domain === "") {
      throw "Invalid addy.io domain.";
    }
    if (options.anonaddy?.baseUrl == null || options.anonaddy.baseUrl === "") {
      throw "Invalid addy.io url.";
    }

    const requestInit: RequestInit = {
      redirect: "manual",
      cache: "no-store",
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + options.apiKey,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      }),
    };
    const url = options.anonaddy.baseUrl + "/api/v1/aliases";
    requestInit.body = JSON.stringify({
      domain: options.anonaddy.domain,
      description:
        (options.website != null ? "Website: " + options.website + ". " : "") +
        "Generated by Bitwarden.",
    });
    const request = new Request(url, requestInit);
    const response = await apiService.nativeFetch(request);
    if (response.status === 200 || response.status === 201) {
      const json = await response.json();
      return json?.data?.email;
    }
    if (response.status === 401) {
      throw "Invalid addy.io API token.";
    }
    if (response?.statusText != null) {
      throw "addy.io error:\n" + response.statusText;
    }
    throw "Unknown addy.io error occurred.";
  }
}
