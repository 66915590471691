<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog
    dialogSize="large"
    [title]="(currentType != null ? 'changePaymentMethod' : 'addPaymentMethod') | i18n"
  >
    <ng-container bitDialogContent>
      <app-payment [hideBank]="!organizationId" [hideCredit]="true"></app-payment>
      <app-tax-info (onCountryChanged)="changeCountry()"></app-tax-info>
    </ng-container>
    <ng-container bitDialogFooter>
      <button type="submit" bitButton bitFormButton buttonType="primary">
        {{ "submit" | i18n }}
      </button>
      <button
        type="button"
        bitButton
        bitFormButton
        buttonType="secondary"
        [bitDialogClose]="DialogResult.Cancelled"
      >
        {{ "cancel" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
