<form (ngSubmit)="submit()">
  <bit-dialog>
    <span bitDialogTitle>
      {{ "collections" | i18n }}
      <small *ngIf="cipher">{{ cipher.name }}</small>
    </span>
    <ng-container bitDialogContent>
      <p>{{ "collectionsDesc" | i18n }}</p>
      <div class="tw-flex">
        <label class="tw-mb-1 tw-block tw-font-semibold tw-text-main">{{
          "collections" | i18n
        }}</label>
        <div class="tw-ml-auto tw-flex" *ngIf="collections && collections.length">
          <button bitLink type="button" (click)="selectAll(true)" class="tw-px-2">
            {{ "selectAll" | i18n }}
          </button>
          <button bitLink type="button" (click)="selectAll(false)" class="tw-px-2">
            {{ "unselectAll" | i18n }}
          </button>
        </div>
      </div>
      <div *ngIf="!collections || !collections.length">
        {{ "noCollectionsInList" | i18n }}
      </div>
      <bit-table *ngIf="collections && collections.length">
        <ng-template body>
          <tr bitRow *ngFor="let c of collections; let i = index" (click)="check(c)">
            <td bitCell>
              <input
                type="checkbox"
                bitCheckbox
                [(ngModel)]="$any(c).checked"
                name="Collection[{{ i }}].Checked"
                appStopProp
                [disabled]="
                  !c.canEditItems(
                    this.organization,
                    this.flexibleCollectionsV1Enabled,
                    this.restrictProviderAccess
                  )
                "
              />
              {{ c.name }}
            </td>
          </tr>
        </ng-template>
      </bit-table>
    </ng-container>
    <ng-container bitDialogFooter>
      <button bitButton buttonType="primary" type="submit">
        {{ "save" | i18n }}
      </button>
      <button bitButton bitDialogClose buttonType="secondary" type="button">
        {{ "cancel" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
