import { ApiService } from "../../../../abstractions/api.service";

import { Forwarder } from "./forwarder";
import { ForwarderOptions } from "./forwarder-options";

export class FirefoxRelayForwarder implements Forwarder {
  async generate(apiService: ApiService, options: ForwarderOptions): Promise<string> {
    if (options.apiKey == null || options.apiKey === "") {
      throw "Invalid Firefox Relay API token.";
    }
    const requestInit: RequestInit = {
      redirect: "manual",
      cache: "no-store",
      method: "POST",
      headers: new Headers({
        Authorization: "Token " + options.apiKey,
        "Content-Type": "application/json",
      }),
    };
    const url = "https://relay.firefox.com/api/v1/relayaddresses/";
    requestInit.body = JSON.stringify({
      enabled: true,
      generated_for: options.website,
      description:
        (options.website != null ? options.website + " - " : "") + "Generated by Bitwarden.",
    });
    const request = new Request(url, requestInit);
    const response = await apiService.nativeFetch(request);
    if (response.status === 200 || response.status === 201) {
      const json = await response.json();
      return json?.full_address;
    }
    if (response.status === 401) {
      throw "Invalid Firefox Relay API token.";
    }
    throw "Unknown Firefox Relay error occurred.";
  }
}
