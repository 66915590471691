<div [formGroup]="data">
  <bit-form-control>
    <input type="checkbox" bitCheckbox [formControl]="enabled" id="enabled" />
    <bit-label>{{ "turnOn" | i18n }}</bit-label>
  </bit-form-control>

  <div class="tw-grid tw-grid-cols-12 tw-gap-4">
    <bit-form-field class="tw-col-span-6 tw-mb-0">
      <bit-label>{{ "defaultType" | i18n }}</bit-label>
      <bit-select formControlName="defaultType" id="defaultType">
        <bit-option *ngFor="let o of defaultTypes" [value]="o.value" [label]="o.name"></bit-option>
      </bit-select>
    </bit-form-field>
  </div>

  <h3 bitTypography="h3" class="tw-mt-4">{{ "password" | i18n }}</h3>
  <div class="tw-grid tw-grid-cols-12 tw-gap-4">
    <bit-form-field class="tw-col-span-6">
      <bit-label>{{ "minLength" | i18n }}</bit-label>
      <input bitInput type="number" min="5" max="128" formControlName="minLength" />
    </bit-form-field>
  </div>
  <div class="tw-grid tw-grid-cols-12 tw-gap-4">
    <bit-form-field class="tw-col-span-6">
      <bit-label>{{ "minNumbers" | i18n }}</bit-label>
      <input bitInput type="number" min="0" max="9" formControlName="minNumbers" />
    </bit-form-field>
    <bit-form-field class="tw-col-span-6">
      <bit-label>{{ "minSpecial" | i18n }}</bit-label>
      <input bitInput type="number" min="0" max="9" formControlName="minSpecial" />
    </bit-form-field>
  </div>
  <bit-form-control>
    <input type="checkbox" bitCheckbox formControlName="useUpper" id="useUpper" />
    <bit-label>A-Z</bit-label>
  </bit-form-control>
  <bit-form-control>
    <input type="checkbox" bitCheckbox formControlName="useLower" id="useLower" />
    <bit-label>a-z</bit-label>
  </bit-form-control>
  <bit-form-control>
    <input type="checkbox" bitCheckbox formControlName="useNumbers" id="useNumbers" />
    <bit-label>0-9</bit-label>
  </bit-form-control>
  <bit-form-control>
    <input type="checkbox" bitCheckbox formControlName="useSpecial" id="useSpecial" />
    <bit-label>!@#$%^&amp;*</bit-label>
  </bit-form-control>
  <h3 bitTypography="h3" class="tw-mt-4">{{ "passphrase" | i18n }}</h3>
  <div class="tw-grid tw-grid-cols-12 tw-gap-4">
    <bit-form-field class="tw-col-span-6">
      <bit-label>{{ "minimumNumberOfWords" | i18n }}</bit-label>
      <input bitInput type="number" min="3" max="20" formControlName="minNumberWords" />
    </bit-form-field>
  </div>
  <bit-form-control>
    <input type="checkbox" bitCheckbox formControlName="capitalize" id="capitalize" />
    <bit-label>{{ "capitalize" | i18n }}</bit-label>
  </bit-form-control>
  <bit-form-control>
    <input type="checkbox" bitCheckbox formControlName="includeNumber" id="includeNumber" />
    <bit-label>{{ "includeNumber" | i18n }}</bit-label>
  </bit-form-control>
</div>
