<ng-container *ngIf="label">
  <fieldset>
    <legend class="tw-mb-1 tw-block tw-text-base tw-font-semibold tw-text-main">
      <ng-content select="bit-label"></ng-content>
      <span *ngIf="required" class="tw-text-xs tw-font-normal"> ({{ "required" | i18n }})</span>
    </legend>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </fieldset>
</ng-container>

<ng-container *ngIf="!label">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-template #content>
  <div>
    <ng-content></ng-content>
  </div>
  <ng-content select="bit-hint" ngProjectAs="bit-hint"></ng-content>
</ng-template>
