<figure>
  <figcaption>
    <cite>
      <img
        src="../../images/register-layout/forbes-logo.svg"
        class="tw-mx-auto tw-block tw-w-40"
        alt="Forbes Logo"
      />
    </cite>
  </figcaption>
  <blockquote class="tw-mx-auto tw-mt-2 tw-max-w-xl tw-px-4 tw-text-center">
    “Bitwarden boasts the backing of some of the world's best security experts and an attractive,
    easy-to-use interface”
  </blockquote>
</figure>
