<ng-container *ngFor="let warning of warnings$ | async">
  <bit-banner
    class="-tw-m-6 tw-flex tw-flex-col tw-pb-6"
    bannerType="warning"
    (onClose)="closeWarning(warning.organizationId)"
  >
    {{ "maintainYourSubscription" | i18n: warning.organizationName }}
    <a
      bitLink
      linkType="contrast"
      [routerLink]="['/organizations', warning.organizationId, 'billing', 'payment-method']"
      >{{ "addAPaymentMethod" | i18n }}</a
    >.
  </bit-banner>
</ng-container>
