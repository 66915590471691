<bit-callout>
  {{ message | i18n }}

  <ul *ngIf="policy" class="tw-mb-0">
    <li *ngIf="policy?.minComplexity > 0">
      {{ "policyInEffectMinComplexity" | i18n: getPasswordScoreAlertDisplay() }}
    </li>
    <li *ngIf="policy?.minLength > 0">
      {{ "policyInEffectMinLength" | i18n: policy?.minLength.toString() }}
    </li>
    <li *ngIf="policy?.requireUpper">
      {{ "policyInEffectUppercase" | i18n }}
    </li>
    <li *ngIf="policy?.requireLower">
      {{ "policyInEffectLowercase" | i18n }}
    </li>
    <li *ngIf="policy?.requireNumbers">
      {{ "policyInEffectNumbers" | i18n }}
    </li>
    <li *ngIf="policy?.requireSpecial">
      {{ "policyInEffectSpecial" | i18n: "!@#$%^&*" }}
    </li>
  </ul>
</bit-callout>
