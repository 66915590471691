<figure>
  <h2 class="tw-mx-auto tw-pb-2 tw-max-w-xl tw-font-semibold tw-text-center">
    {{ header }}
  </h2>
  <blockquote class="tw-mx-auto tw-my-2 tw-max-w-xl tw-px-4 tw-text-center">
    "{{ quote }}"
  </blockquote>
  <figcaption>
    <cite>
      <p class="tw-mx-auto tw-text-center tw-font-bold">{{ source }}</p>
    </cite>
  </figcaption>
</figure>
