<h1 class="tw-text-4xl !tw-text-alt2">{{ header }}</h1>
<div class="tw-pt-16">
  <h2 class="tw-text-2xl tw-font-semibold">
    {{ headline }}
  </h2>
</div>
<ul class="tw-mt-12 tw-flex tw-flex-col tw-gap-10 tw-text-2xl tw-text-main">
  <li *ngFor="let primaryPoint of primaryPoints">
    {{ primaryPoint }}
  </li>
</ul>
<div class="tw-mt-12 tw-flex tw-flex-col">
  <div class="tw-rounded-[32px] tw-bg-background">
    <div class="tw-my-8 tw-mx-6">
      <h2 class="tw-pl-5 tw-font-semibold">{{ calloutHeadline }}</h2>
      <ul class="tw-space-y-4 tw-mt-4 tw-pl-10">
        <li *ngFor="let callout of callouts">
          {{ callout }}
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="tw-mt-12 tw-flex tw-flex-col tw-items-center tw-gap-5">
  <app-review-blurb
    header="Businesses trust Bitwarden to secure their infrastructure"
    quote="At this point, it would be almost impossible to leak our secrets. It's just one less thing we have to worry about."
    source="Titanom Technologies"
  ></app-review-blurb>
</div>
