<div [formGroup]="form">
  <bit-form-field>
    <bit-label>{{ "vaultTimeout" | i18n }}</bit-label>
    <bit-select formControlName="vaultTimeout">
      <bit-option
        *ngFor="let o of vaultTimeoutOptions"
        [value]="o.value"
        [label]="o.name"
      ></bit-option>
    </bit-select>
    <bit-hint class="tw-text-sm">{{
      ((canLockVault$ | async) ? "vaultTimeoutDesc" : "vaultTimeoutLogoutDesc") | i18n
    }}</bit-hint>
  </bit-form-field>
  <div class="tw-grid tw-grid-cols-12 tw-gap-4" *ngIf="showCustom" formGroupName="custom">
    <bit-form-field class="tw-col-span-6">
      <bit-label>{{ "customVaultTimeout" | i18n }}</bit-label>
      <input bitInput type="number" min="0" formControlName="hours" />
      <bit-hint>{{ "hours" | i18n }}</bit-hint>
    </bit-form-field>
    <bit-form-field class="tw-col-span-6 tw-self-end">
      <input bitInput type="number" min="0" name="minutes" formControlName="minutes" />
      <bit-hint>{{ "minutes" | i18n }}</bit-hint>
    </bit-form-field>
  </div>
  <small *ngIf="!exceedsMinimumTimout" class="tw-text-danger">
    <i class="bwi bwi-error" aria-hidden="true"></i> {{ "vaultCustomTimeoutMinimum" | i18n }}
  </small>
</div>
