<router-outlet></router-outlet>

<div class="row mt-4">
  <div class="col">
    <a bitButton routerLink="./" *ngIf="!homepage">
      <i class="bwi bwi-angle-left" aria-hidden="true"></i>
      {{ "backToReports" | i18n }}
    </a>
  </div>
</div>
