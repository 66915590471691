<bit-callout type="info" *ngIf="showKeyConnectorInfo">
  {{ "keyConnectorPolicyRestriction" | i18n }}
</bit-callout>

<bit-callout type="success" [title]="'prerequisite' | i18n" icon="bwi-lightbulb">
  {{ "accountRecoverySingleOrgRequirementDesc" | i18n }}
</bit-callout>

<bit-callout type="warning">
  {{ "accountRecoveryPolicyWarning" | i18n }}
</bit-callout>

<bit-form-control class="!tw-mb-1">
  <input type="checkbox" bitCheckbox [formControl]="enabled" id="enabled" />
  <bit-label>{{ "turnOn" | i18n }}</bit-label>
</bit-form-control>

<ng-container [formGroup]="data">
  <bit-form-control class="!tw-mb-1">
    <input type="checkbox" bitCheckbox formControlName="autoEnrollEnabled" id="autoEnrollEnabled" />
    <bit-label>{{ "resetPasswordPolicyAutoEnrollCheckbox" | i18n }}</bit-label>
  </bit-form-control>
</ng-container>
