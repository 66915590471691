<form [bitSubmit]="submitAndClose" [formGroup]="formGroup">
  <bit-dialog>
    <span bitDialogTitle>
      {{ title }}
    </span>
    <span bitDialogContent>
      <bit-form-field>
        <bit-label>{{ "name" | i18n }}</bit-label>
        <input bitInput id="name" formControlName="name" />
      </bit-form-field>
    </span>
    <ng-container bitDialogFooter>
      <button bitButton buttonType="primary" bitFormButton type="submit">
        <span>{{ "save" | i18n }}</span>
      </button>
      <button bitButton buttonType="secondary" bitDialogClose type="button" data-dismiss="modal">
        {{ "cancel" | i18n }}
      </button>
      <div class="tw-m-0 tw-ml-auto">
        <button
          buttonType="danger"
          bitIconButton="bwi-trash"
          bitFormButton
          type="button"
          appA11yTitle="{{ 'delete' | i18n }}"
          *ngIf="editMode"
          [bitAction]="deleteAndClose"
        ></button>
      </div>
    </ng-container>
  </bit-dialog>
</form>
