<span class="tw-relative">
  <span [ngClass]="{ 'tw-invisible': loading }">
    <i class="bwi" [ngClass]="iconClass" aria-hidden="true"></i>
  </span>
  <span
    class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center"
    [ngClass]="{ 'tw-invisible': !loading }"
  >
    <i
      class="bwi bwi-spinner bwi-spin"
      aria-hidden="true"
      [ngClass]="{ 'bwi-lg': size === 'default' }"
    ></i>
  </span>
</span>
