<figure>
  <figcaption>
    <cite>
      <img
        src="../../images/register-layout/vault-signup-badges.png"
        class="tw-mx-auto tw-block tw-w-full"
        alt="third party awards"
      />
    </cite>
  </figcaption>
</figure>
