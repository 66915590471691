<a
  bitTabListItem
  [routerLink]="disabled ? null : route"
  routerLinkActive
  [routerLinkActiveOptions]="routerLinkMatchOptions"
  #rla="routerLinkActive"
  [active]="rla.isActive"
  [disabled]="disabled"
  [attr.aria-disabled]="disabled"
  ariaCurrentWhenActive="page"
  role="link"
>
  <ng-content></ng-content>
</a>
