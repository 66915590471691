<figure>
  <figcaption>
    <cite>
      <img
        src="../../images/register-layout/cnet-logo.svg"
        class="tw-mx-auto tw-block tw-w-40"
        alt="CNET Logo"
      />
    </cite>
  </figcaption>
  <blockquote class="tw-mx-auto tw-mt-2 tw-max-w-xl tw-px-4 tw-text-center">
    "No more excuses; start using Bitwarden today. The identity you save could be your own. The
    money definitely will be."
  </blockquote>
</figure>
