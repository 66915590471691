<div
  class="tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-6 tw-text-center"
>
  <div class="tw-max-w-sm">
    <bit-icon [icon]="icon" aria-hidden="true"></bit-icon>
    <h3 class="tw-font-semibold">
      <ng-content select="[slot=title]"></ng-content>
    </h3>
    <p>
      <ng-content select="[slot=description]"></ng-content>
    </p>
  </div>
  <div class="tw-space-x-2">
    <ng-content select="[slot=button]"></ng-content>
  </div>
</div>
