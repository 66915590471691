<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate autocomplete="off">
  <div class="tw-mt-12 tw-flex tw-justify-center">
    <div class="tw-w-1/3">
      <h1 bitTypography="h1" class="tw-mb-4 tw-text-center">{{ "updateMasterPassword" | i18n }}</h1>
      <div
        class="tw-block tw-rounded tw-border tw-border-solid tw-border-secondary-300 tw-bg-background tw-p-8"
      >
        <app-callout type="warning">{{ masterPasswordWarningText }} </app-callout>
        <auth-password-callout [policy]="enforcedPolicyOptions" *ngIf="enforcedPolicyOptions">
        </auth-password-callout>
        <bit-form-field *ngIf="requireCurrentPassword">
          <bit-label>{{ "currentMasterPass" | i18n }}</bit-label>
          <input
            bitInput
            type="password"
            appInputVerbatim
            required
            [(ngModel)]="verification.secret"
            name="currentMasterPassword"
            id="currentMasterPassword"
            [appAutofocus]="requireCurrentPassword"
          />
          <button type="button" bitIconButton bitSuffix bitPasswordInputToggle></button>
        </bit-form-field>
        <div class="tw-mb-4">
          <bit-form-field class="!tw-mb-1">
            <bit-label>{{ "newMasterPass" | i18n }}</bit-label>
            <input
              bitInput
              type="password"
              appInputVerbatim
              required
              [(ngModel)]="masterPassword"
              name="masterPassword"
              id="masterPassword"
            />
            <button
              type="button"
              bitIconButton
              bitSuffix
              bitPasswordInputToggle
              [(toggled)]="showPassword"
            ></button>
          </bit-form-field>
          <app-password-strength
            [password]="masterPassword"
            [email]="email"
            [showText]="true"
            (passwordStrengthResult)="getStrengthResult($event)"
          >
          </app-password-strength>
        </div>
        <bit-form-field>
          <bit-label>{{ "confirmNewMasterPass" | i18n }}</bit-label>
          <input
            bitInput
            type="password"
            appInputVerbatim
            required
            [(ngModel)]="masterPasswordRetype"
            name="masterPasswordRetype"
            id="masterPasswordRetype"
          />
          <button
            type="button"
            bitIconButton
            bitSuffix
            bitPasswordInputToggle
            [(toggled)]="showPassword"
          ></button>
        </bit-form-field>
        <bit-form-field>
          <bit-label>{{ "masterPassHint" | i18n }}</bit-label>
          <input bitInput type="text" [(ngModel)]="hint" name="hint" id="hint" />
          <bit-hint>{{ "masterPassHintDesc" | i18n }}</bit-hint>
        </bit-form-field>
        <hr />
        <div class="tw-flex tw-space-x-2">
          <button
            type="submit"
            bitButton
            [block]="true"
            buttonType="primary"
            [loading]="form.loading"
            [disabled]="form.loading"
          >
            {{ "submit" | i18n }}
          </button>
          <button type="button" bitButton [block]="true" buttonType="secondary" (click)="logOut()">
            {{ "logOut" | i18n }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
