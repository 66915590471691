<form [formGroup]="formGroup" [bitSubmit]="submit">
  <app-callout type="warning" *ngIf="showTwoFactorEmailWarning">
    {{ "changeEmailTwoFactorWarning" | i18n }}
  </app-callout>

  <div class="tw-w-1/2 tw-pr-2" formGroupName="step1">
    <bit-form-field>
      <bit-label>{{ "masterPass" | i18n }}</bit-label>
      <input
        id="change-email_input_masterPassword"
        bitInput
        type="password"
        formControlName="masterPassword"
      />
      <button type="button" bitIconButton bitSuffix bitPasswordInputToggle></button>
    </bit-form-field>
    <bit-form-field>
      <bit-label>{{ "newEmail" | i18n }}</bit-label>
      <input
        id="change-email_input_newEmail"
        bitInput
        type="email"
        formControlName="newEmail"
        inputmode="email"
      />
    </bit-form-field>
  </div>

  <ng-container *ngIf="tokenSent">
    <hr />
    <p>{{ "changeEmailDesc" | i18n: formGroup.controls.step1.value.newEmail }}</p>
    <app-callout type="warning">{{ "loggedOutWarning" | i18n }}</app-callout>

    <div class="tw-w-1/2 tw-pr-2">
      <bit-form-field>
        <bit-label>{{ "code" | i18n }}</bit-label>
        <input id="change-email_input_token" bitInput type="text" formControlName="token" />
      </bit-form-field>
    </div>
  </ng-container>

  <button type="submit" bitButton buttonType="primary" bitFormButton>
    {{ (tokenSent ? "changeEmail" : "continue") | i18n }}
  </button>
  <button type="button" bitButton *ngIf="tokenSent" (click)="reset()">
    {{ "cancel" | i18n }}
  </button>
</form>
