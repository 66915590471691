<h1 bitTypography="h1">{{ "vault" | i18n }}</h1>

<div class="tw-mt-6">
  <ng-container *ngIf="ciphers.length">
    <bit-table>
      <ng-template body>
        <tr bitRow *ngFor="let currentCipher of ciphers">
          <td bitCell>
            <app-vault-icon [cipher]="currentCipher"></app-vault-icon>
          </td>
          <td bitCell class="tw-w-full">
            <a
              bitLink
              href="#"
              appStopClick
              (click)="selectCipher(currentCipher)"
              title="{{ 'editItem' | i18n }}"
              >{{ currentCipher.name }}</a
            >
            <ng-container *ngIf="currentCipher.organizationId">
              <i
                class="bwi bwi-collection"
                appStopProp
                title="{{ 'shared' | i18n }}"
                aria-hidden="true"
              ></i>
              <span class="tw-sr-only">{{ "shared" | i18n }}</span>
            </ng-container>
            <ng-container *ngIf="currentCipher.hasAttachments">
              <i
                class="bwi bwi-paperclip"
                appStopProp
                title="{{ 'attachments' | i18n }}"
                aria-hidden="true"
              ></i>
              <span class="tw-sr-only">{{ "attachments" | i18n }}</span>
            </ng-container>
            <br />
            <small class="tw-text-xs">{{ currentCipher.subTitle }}</small>
          </td>
          <td bitCell>
            <div *ngIf="currentCipher.hasAttachments">
              <button
                [bitMenuTriggerFor]="optionsMenu"
                type="button"
                buttonType="main"
                bitIconButton="bwi-ellipsis-v"
                appA11yTitle="{{ 'options' | i18n }}"
              ></button>
              <bit-menu #optionsMenu>
                <button
                  type="button"
                  bitMenuItem
                  appStopClick
                  (click)="viewAttachments(currentCipher)"
                >
                  <i class="bwi bwi-fw bwi-paperclip" aria-hidden="true"></i>
                  {{ "attachments" | i18n }}
                </button>
              </bit-menu>
            </div>
          </td>
        </tr>
      </ng-template>
    </bit-table>
  </ng-container>
  <ng-container *ngIf="!loaded">
    <i
      class="bwi bwi-spinner bwi-spin tw-text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="tw-sr-only">{{ "loading" | i18n }}</span>
  </ng-container>
</div>
<ng-template #cipherAddEdit></ng-template>
<ng-template #attachments></ng-template>
