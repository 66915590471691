<app-vertical-stepper #stepper linear>
  <app-vertical-step
    label="{{ 'createAccount' | i18n | titlecase }}"
    [editable]="false"
    [subLabel]="subLabels.createAccount"
    [addSubLabelSpacing]="true"
  >
    <app-register-form
      [referenceDataValue]="referenceEventRequest"
      [isInTrialFlow]="true"
      (createdAccount)="accountCreated($event)"
    >
    </app-register-form>
  </app-vertical-step>
  <app-vertical-step
    label="{{ 'organizationInformation' | i18n | titlecase }}"
    [subLabel]="subLabels.organizationInfo"
  >
    <app-org-info [nameOnly]="true" [formGroup]="formGroup"> </app-org-info>
    <button
      type="button"
      bitButton
      buttonType="primary"
      [disabled]="formGroup.get('name').invalid"
      (click)="createOrganization()"
    >
      {{ "next" | i18n }}
    </button>
  </app-vertical-step>
  <app-vertical-step label="{{ 'confirmationDetails' | i18n | titlecase }}">
    <div class="tw-pb-6 tw-pl-6">
      <p class="tw-text-xl">{{ "smFreeTrialThankYou" | i18n }}</p>
      <ul class="tw-list-disc">
        <li>
          <p>
            {{ "smFreeTrialConfirmationEmail" | i18n }}
            <span class="tw-font-bold">{{ formGroup.get("email").value }}</span
            >.
          </p>
        </li>
      </ul>
    </div>
    <div class="tw-mb-3 tw-flex">
      <button type="button" bitButton buttonType="primary" (click)="navigateToSecretsManager()">
        {{ "getStarted" | i18n | titlecase }}
      </button>
      <button
        type="button"
        bitButton
        buttonType="secondary"
        (click)="navigateToMembers()"
        class="tw-ml-3 tw-inline-flex tw-items-center tw-px-3"
      >
        {{ "inviteUsers" | i18n }}
      </button>
    </div>
  </app-vertical-step>
</app-vertical-stepper>
