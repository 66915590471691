<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog>
    <span bitDialogTitle>
      {{ "passwordConfirmation" | i18n }}
    </span>
    <ng-container bitDialogContent>
      {{ "passwordConfirmationDesc" | i18n }}

      <bit-form-field disableMargin class="tw-mt-6">
        <bit-label>{{ "masterPass" | i18n }}</bit-label>
        <input
          bitInput
          appAutofocus
          id="masterPassword"
          type="password"
          formControlName="masterPassword"
        />
        <button type="button" bitSuffix bitIconButton bitPasswordInputToggle></button>
      </bit-form-field>
    </ng-container>

    <ng-container bitDialogFooter>
      <button bitButton buttonType="primary" bitFormButton type="submit">
        <span>{{ "ok" | i18n }}</span>
      </button>
      <button bitButton buttonType="secondary" bitDialogClose type="button">
        {{ "cancel" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
