<bit-dialog dialogSize="default">
  <span bitDialogTitle>
    {{ "twoStepLogin" | i18n }}
    <span bitTypography="body1">{{ "recoveryCodeTitle" | i18n }}</span>
  </span>
  <ng-container *ngIf="authed" bitDialogContent>
    <ng-container *ngIf="code">
      <p bitTypography="body1" class="tw-text-center">{{ "twoFactorRecoveryYourCode" | i18n }}:</p>
      <code class="tw-text-lg tw-w-full tw-flex tw-justify-center">{{ code }}</code>
    </ng-container>
    <ng-container *ngIf="!code">
      {{ "twoFactorRecoveryNoCode" | i18n }}
    </ng-container>
  </ng-container>
  <ng-container bitDialogFooter>
    <button bitButton type="button" buttonType="primary" (click)="print()" *ngIf="code">
      {{ "printCode" | i18n }}
    </button>
    <button bitButton type="button" buttonType="secondary" bitDialogClose>
      {{ "close" | i18n }}
    </button>
  </ng-container>
</bit-dialog>
