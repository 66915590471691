<div class="progress">
  <div
    class="progress-bar {{ color }}"
    role="progressbar"
    [ngStyle]="{ width: scoreWidth + '%' }"
    attr.aria-valuenow="{{ scoreWidth }}"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    <ng-container *ngIf="showText && text">
      {{ text }}
    </ng-container>
  </div>
</div>
