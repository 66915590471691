<app-header>
  <bit-tab-nav-bar slot="tabs">
    <bit-tab-link route="change-password">{{ "masterPassword" | i18n }}</bit-tab-link>
    <bit-tab-link route="two-factor">{{ "twoStepLogin" | i18n }}</bit-tab-link>
    <bit-tab-link route="security-keys">{{ "keys" | i18n }}</bit-tab-link>
  </bit-tab-nav-bar>
</app-header>

<bit-container>
  <router-outlet></router-outlet>
</bit-container>
