<div class="tabbed-header">
  <h1>{{ "encKeySettings" | i18n }}</h1>
</div>
<bit-callout type="warning">{{ "kdfSettingsChangeLogoutWarning" | i18n }}</bit-callout>
<form #form ngNativeValidate autocomplete="off">
  <div class="row">
    <div class="col-6">
      <div class="form-group mb-0">
        <label for="kdf">{{ "kdfAlgorithm" | i18n }}</label>
        <a
          class="ml-auto"
          href="https://bitwarden.com/help/kdf-algorithms"
          target="_blank"
          rel="noreferrer"
          appA11yTitle="{{ 'learnMore' | i18n }}"
        >
          <i class="bwi bwi-question-circle" aria-hidden="true"></i>
        </a>
        <select
          id="kdf"
          name="Kdf"
          [(ngModel)]="kdfConfig.kdfType"
          (ngModelChange)="onChangeKdf($event)"
          class="form-control mb-3"
          required
        >
          <option *ngFor="let o of kdfOptions" [ngValue]="o.value">{{ o.name }}</option>
        </select>
        <ng-container *ngIf="isArgon2(kdfConfig)">
          <label for="kdfMemory">{{ "kdfMemory" | i18n }}</label>
          <input
            id="kdfMemory"
            type="number"
            [min]="ARGON2_MEMORY.min"
            [max]="ARGON2_MEMORY.max"
            name="Memory"
            class="form-control mb-3"
            [(ngModel)]="kdfConfig.memory"
            required
          />
        </ng-container>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group mb-0">
        <ng-container *ngIf="isPBKDF2(kdfConfig)">
          <label for="kdfIterations">{{ "kdfIterations" | i18n }}</label>
          <a
            class="ml-auto"
            href="https://bitwarden.com/help/what-encryption-is-used/#changing-kdf-iterations"
            target="_blank"
            rel="noreferrer"
            appA11yTitle="{{ 'learnMore' | i18n }}"
          >
            <i class="bwi bwi-question-circle" aria-hidden="true"></i>
          </a>
          <input
            id="kdfIterations"
            type="number"
            [min]="PBKDF2_ITERATIONS.min"
            [max]="PBKDF2_ITERATIONS.max"
            name="KdfIterations"
            class="form-control"
            [(ngModel)]="kdfConfig.iterations"
            required
          />
        </ng-container>
        <ng-container *ngIf="isArgon2(kdfConfig)">
          <label for="kdfIterations">{{ "kdfIterations" | i18n }}</label>
          <input
            id="iterations"
            type="number"
            [min]="ARGON2_ITERATIONS.min"
            [max]="ARGON2_ITERATIONS.max"
            name="Iterations"
            class="form-control mb-3"
            [(ngModel)]="kdfConfig.iterations"
            required
          />
          <label for="kdfParallelism">{{ "kdfParallelism" | i18n }}</label>
          <input
            id="kdfParallelism"
            type="number"
            [min]="ARGON2_PARALLELISM.min"
            [max]="ARGON2_PARALLELISM.max"
            name="Parallelism"
            class="form-control"
            [(ngModel)]="kdfConfig.parallelism"
            required
          />
        </ng-container>
      </div>
    </div>
    <div class="col-12">
      <ng-container *ngIf="isPBKDF2(kdfConfig)">
        <p class="small form-text text-muted">
          {{ "kdfIterationsDesc" | i18n: (PBKDF2_ITERATIONS.defaultValue | number) }}
        </p>
        <bit-callout type="warning">
          {{ "kdfIterationsWarning" | i18n: (100000 | number) }}
        </bit-callout>
      </ng-container>
      <ng-container *ngIf="isArgon2(kdfConfig)">
        <p class="small form-text text-muted">{{ "argon2Desc" | i18n }}</p>
        <bit-callout type="warning"> {{ "argon2Warning" | i18n }}</bit-callout>
      </ng-container>
    </div>
  </div>
  <button
    (click)="openConfirmationModal()"
    type="button"
    buttonType="primary"
    bitButton
    [loading]="form.loading"
  >
    {{ "changeKdf" | i18n }}
  </button>
</form>
