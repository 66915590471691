import { svgIcon } from "@bitwarden/components";

export const UserVerificationBiometricsIcon = svgIcon`
<svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" fill="none">
    <path class="tw-fill-secondary-600" fill="#89929F" fill-rule="evenodd" d="M6.539 13.582C12.113 5.786 21.228.7 31.529.7c15.02 0 27.512 10.808 30.203 25.086a2 2 0 1 1-3.93.74C55.457 14.093 44.578 4.7 31.528 4.7c-8.952 0-16.879 4.416-21.736 11.21a2 2 0 0 1-3.254-2.327Zm-.955 5.384A2 2 0 0 1 6.7 21.565a26.876 26.876 0 0 0-1.91 9.988v8.833a2 2 0 1 1-4 0v-8.833c0-4.05.778-7.923 2.195-11.472a2 2 0 0 1 2.599-1.115Zm54.685 10.587a2 2 0 0 1 2 2v8.244a2 2 0 0 1-4 0v-8.244a2 2 0 0 1 2-2Z" clip-rule="evenodd"/>
    <path class="tw-fill-secondary-600" fill="#89929F" fill-rule="evenodd" d="M8.476 21.293c3.898-8.848 12.751-15.032 23.053-15.032a25.08 25.08 0 0 1 14.296 4.448A2 2 0 1 1 43.552 14a21.08 21.08 0 0 0-12.023-3.739c-8.66 0-16.11 5.196-19.392 12.645a2 2 0 1 1-3.661-1.613Zm39.328-6.481a2 2 0 0 1 2.82.211 25.062 25.062 0 0 1 6.082 16.4v19.629a2 2 0 1 1-4 0V31.423c0-5.27-1.925-10.085-5.114-13.79a2 2 0 0 1 .212-2.821ZM8.728 26.786A2 2 0 0 1 10.49 29c-.09.794-.137 1.603-.137 2.423v19.629a2 2 0 1 1-4 0V31.423c0-.972.055-1.931.163-2.876a2 2 0 0 1 2.213-1.76Z" clip-rule="evenodd"/>
    <path class="tw-fill-secondary-600" fill="#89929F" fill-rule="evenodd" d="M12.223 31.097c0-10.648 8.647-19.273 19.306-19.273s19.306 8.625 19.306 19.273v25.321a2 2 0 1 1-4 0v-25.32c0-8.433-6.85-15.274-15.306-15.274s-15.305 6.841-15.305 15.273v9.913a2 2 0 1 1-4 0v-9.913Zm2 13.409a2 2 0 0 1 2 2v9.912a2 2 0 1 1-4 0v-9.912a2 2 0 0 1 2-2Z" clip-rule="evenodd"/>
    <path class="tw-fill-secondary-600" fill="#89929F" fill-rule="evenodd" d="M24.698 19.044a13.418 13.418 0 0 1 6.831-1.857c7.411 0 13.434 5.984 13.434 13.385v7.851a2 2 0 1 1-4 0v-7.851c0-5.175-4.216-9.385-9.434-9.385a9.419 9.419 0 0 0-4.8 1.304 2 2 0 0 1-2.031-3.447Zm-1.76 3.755a2 2 0 0 1 .613 2.762 9.296 9.296 0 0 0-1.456 5.01v29.64a2 2 0 0 1-4 0v-29.64c0-2.63.763-5.087 2.081-7.158a2 2 0 0 1 2.761-.614Zm20.025 20.298a2 2 0 0 1 2 2v15.114a2 2 0 1 1-4 0V45.097a2 2 0 0 1 2-2Z" clip-rule="evenodd"/>
    <path class="tw-fill-secondary-600" fill="#89929F" fill-rule="evenodd" d="M23.967 30.18c0-4.163 3.408-7.497 7.562-7.497s7.563 3.334 7.563 7.496v12.563a2 2 0 0 1-4 0V30.179c0-1.908-1.573-3.496-3.563-3.496-1.99 0-3.562 1.588-3.562 3.496v31.603a2 2 0 0 1-4 0V30.179ZM37.092 46.04a2 2 0 0 1 2 2v13.74a2 2 0 0 1-4 0v-13.74a2 2 0 0 1 2-2Z" clip-rule="evenodd"/>
    <path class="tw-fill-secondary-600" fill="#89929F" fill-rule="evenodd" d="M31.546 28.375a2 2 0 0 1 2 2v4.908a2 2 0 1 1-4 0v-4.908a2 2 0 0 1 2-2Zm-.018 10.334a2 2 0 0 1 2.001 1.999l.017 22.25a2 2 0 1 1-4 .003l-.017-22.25a2 2 0 0 1 1.999-2.002Z" clip-rule="evenodd"/>
</svg>
`;
