<div class="tw-container tw-mx-auto">
  <div
    class="tw-mx-auto tw-mt-5 tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-center tw-p-8"
  >
    <div class="tw-mb-6">
      <img class="logo logo-themed" alt="Bitwarden" />
    </div>

    <ng-container *ngIf="loading">
      <p class="text-center">
        <i
          class="bwi bwi-spinner bwi-spin bwi-2x text-muted"
          title="{{ 'loading' | i18n }}"
          aria-hidden="true"
        ></i>
        <span class="sr-only">{{ "loading" | i18n }}</span>
      </p>
    </ng-container>

    <div
      *ngIf="!loading"
      class="tw-w-full tw-rounded-md tw-border tw-border-solid tw-border-secondary-300 tw-bg-background tw-p-6"
    >
      <ng-container *ngIf="data.state == State.ExistingUserUntrustedDevice">
        <h2 bitTypography="h2" class="tw-mb-6">{{ "loginInitiated" | i18n }}</h2>

        <p bitTypography="body1" class="tw-mb-6">
          {{ "deviceApprovalRequired" | i18n }}
        </p>

        <form [formGroup]="rememberDeviceForm">
          <bit-form-control>
            <input type="checkbox" bitCheckbox formControlName="rememberDevice" />
            <bit-label>{{ "rememberThisDevice" | i18n }} </bit-label>
            <bit-hint bitTypography="body2">{{ "uncheckIfPublicDevice" | i18n }}</bit-hint>
          </bit-form-control>
        </form>

        <div class="tw-mb-6 tw-flex tw-flex-col tw-space-y-3">
          <button
            *ngIf="data.showApproveFromOtherDeviceBtn"
            (click)="approveFromOtherDevice()"
            bitButton
            type="button"
            buttonType="primary"
            block
          >
            {{ "approveFromYourOtherDevice" | i18n }}
          </button>

          <button
            *ngIf="data.showReqAdminApprovalBtn"
            (click)="requestAdminApproval()"
            bitButton
            type="button"
            buttonType="secondary"
          >
            {{ "requestAdminApproval" | i18n }}
          </button>

          <button
            *ngIf="data.showApproveWithMasterPasswordBtn"
            (click)="approveWithMasterPassword()"
            bitButton
            type="button"
            buttonType="secondary"
            block
          >
            {{ "approveWithMasterPassword" | i18n }}
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="data.state == State.NewUser">
        <h2 bitTypography="h2" class="tw-mb-6">{{ "loggedInExclamation" | i18n }}</h2>

        <form [formGroup]="rememberDeviceForm">
          <bit-form-control>
            <input type="checkbox" bitCheckbox formControlName="rememberDevice" />
            <bit-label>{{ "rememberThisDevice" | i18n }} </bit-label>
            <bit-hint bitTypography="body2">{{ "uncheckIfPublicDevice" | i18n }}</bit-hint>
          </bit-form-control>
        </form>

        <button
          bitButton
          type="button"
          buttonType="primary"
          block
          class="tw-mb-6"
          [bitAction]="createUserAction"
        >
          {{ "continue" | i18n }}
        </button>
      </ng-container>

      <hr class="tw-mb-6 tw-mt-0" />

      <div class="tw-m-0 tw-text-sm">
        <p class="tw-mb-1">{{ "loggingInAs" | i18n }} {{ data.userEmail }}</p>
        <a [routerLink]="[]" (click)="logOut()">{{ "notYou" | i18n }}</a>
      </div>
    </div>
  </div>
</div>
