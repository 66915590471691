<div class="tw-inline-grid tw-grid-cols-3 tw-gap-4">
  <div *ngFor="let report of reports">
    <app-report-card
      [title]="report.title | i18n"
      [description]="report.description | i18n"
      [route]="report.route"
      [variant]="report.variant"
      [icon]="report.icon"
    ></app-report-card>
  </div>
</div>
