export enum PlanType {
  Free = 0,
  FamiliesAnnually2019 = 1,
  TeamsMonthly2019 = 2,
  TeamsAnnually2019 = 3,
  EnterpriseMonthly2019 = 4,
  EnterpriseAnnually2019 = 5,
  Custom = 6,
  FamiliesAnnually = 7,
  TeamsMonthly2020 = 8,
  TeamsAnnually2020 = 9,
  EnterpriseMonthly2020 = 10,
  EnterpriseAnnually2020 = 11,
  TeamsMonthly2023 = 12,
  TeamsAnnually2023 = 13,
  EnterpriseMonthly2023 = 14,
  EnterpriseAnnually2023 = 15,
  TeamsStarter2023 = 16,
  TeamsMonthly = 17,
  TeamsAnnually = 18,
  EnterpriseMonthly = 19,
  EnterpriseAnnually = 20,
  TeamsStarter = 21,
}
