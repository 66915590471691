<ng-container *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="sr-only">{{ "loading" | i18n }}</span>
</ng-container>
<form
  #form
  [formGroup]="formGroup"
  [appApiAction]="formPromise"
  (ngSubmit)="submit()"
  *ngIf="!loading"
>
  <div class="tw-container tw-mb-3">
    <div class="tw-mb-6">
      <h2 class="tw-mb-3 tw-text-base tw-font-semibold">{{ "billingPlanLabel" | i18n }}</h2>
      <div class="tw-mb-1 tw-items-center" *ngIf="annualPlan !== null">
        <label class="tw- tw-block tw-text-main" for="annual">
          <input
            class="tw-h-4 tw-w-4 tw-align-middle"
            id="annual"
            name="cadence"
            type="radio"
            [value]="annualCadence"
            formControlName="cadence"
          />
          {{ "annual" | i18n }} -
          {{ getPriceFor(annualCadence) | currency: "$" }}
          /{{ "yr" | i18n }}
        </label>
      </div>
      <div class="tw-mb-1 tw-items-center" *ngIf="monthlyPlan !== null">
        <label class="tw- tw-block tw-text-main" for="monthly">
          <input
            class="tw-h-4 tw-w-4 tw-align-middle"
            id="monthly"
            name="cadence"
            type="radio"
            [value]="monthlyCadence"
            formControlName="cadence"
          />
          {{ "monthly" | i18n }} -
          {{ getPriceFor(monthlyCadence) | currency: "$" }}
          /{{ "monthAbbr" | i18n }}
        </label>
      </div>
    </div>
    <div class="tw-mb-4">
      <h2 class="tw-mb-3 tw-text-base tw-font-semibold">{{ "paymentType" | i18n }}</h2>
      <app-payment [hideCredit]="true" [trialFlow]="true"></app-payment>
      <app-tax-info [trialFlow]="true" (onCountryChanged)="changedCountry()"></app-tax-info>
    </div>
    <div class="tw-flex tw-space-x-2">
      <button type="submit" buttonType="primary" bitButton [loading]="form.loading">
        {{ "startTrial" | i18n }}
      </button>
      <button bitButton type="button" buttonType="secondary" (click)="stepBack()">Back</button>
    </div>
  </div>
</form>
