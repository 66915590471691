<p bitTypography="body1">{{ "sendProtectedPassword" | i18n }}</p>
<p bitTypography="body1">{{ "sendProtectedPasswordDontKnow" | i18n }}</p>
<div class="tw-mb-3" [formGroup]="formGroup">
  <bit-form-field>
    <bit-label>{{ "password" | i18n }}</bit-label>
    <input
      bitInput
      type="password"
      formControlName="password"
      required
      appInputVerbatim
      appAutofocus
    />
    <button type="button" bitIconButton bitSuffix bitPasswordInputToggle></button>
  </bit-form-field>
  <div class="tw-flex">
    <button
      bitButton
      bitFormButton
      type="submit"
      buttonType="primary"
      [loading]="loading"
      [block]="true"
    >
      <span> <i class="bwi bwi-sign-in" aria-hidden="true"></i> {{ "continue" | i18n }} </span>
    </button>
  </div>
</div>
